.middle {
  padding: 11px 0px 0px 211px !important;
}
.forgot-title {
  padding: 0 0 30px 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.666667px;
  color: #000000;
}
.alert {
  padding: 0 0 10px 0;
}
.MuiAlert-filledError {
  color: #fffefe !important;
  background-color: #f29577 !important;
}

.MuiAlert-filledSuccess {
  color: #fffefe !important;
}
.p-t10 {
  margin: 10px 0 0 0;
}
