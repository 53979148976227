.signup {
  background:#57cef1;
  

  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
  }
}
