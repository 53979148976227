.sidenav-1 {
  .active-item {
    background-color: #34353d !important;
    border-left: 3px solid #24cef1 !important;
  }
  .min-w48 {
    min-height: 48px !important;
    padding: 0px 16px 0 22px !important;
  }
  .MuiAccordion-root-46 {
    margin: 0 !important;
    position: initial !important;
    min-height: 48px !important;
  }
  .MuiPaper-root-44 {
    background-color: #42424c !important;
  }
  .heading-name {
    padding: 0 0px 0 15px !important;
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.373372px !important;
    color: #ffffff !important;
  }

  .users-icon {
    height: 17px;
    width: 17px;
  }
  .text-icon {
    font-weight: 500 !important;
    padding: 2px 0 0 11px !important;
    font-size: 0.875rem !important;
    display: inline-block !important;
    height: 21px !important;
    width: 21px !important;
    line-height: 21px !important;
  }
  .pd-30 {
    padding: 4px 0 0 5px !important;
  }
  .MuiPaper-elevation1-43 {
    box-shadow: none !important;
  }
  .MuiAccordionSummary-root {
    padding: 0 16px 0 22px !important;
  }
}
