.p-8 {
  padding: 8px !important;
  width: 525px !important;
}
.MuiButton-containedPrimary {
  background-color: #003a75 !important;
}
.text-primary {
  color: #003a75 !important;
}
.MuiButton-root {
  margin-left: 123px !important;
  min-width: 188px !important;
}
.signup .signup-card {
  background-color: white !important;
}
.login-title {
  padding: 0 0 30px 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.666667px;
  color: #000000;
}
.error-alert {
  padding: 0 0 10px 0;
}
.MuiAlert-filledError {
  color: #fffefe;
  background-color: #f29577 !important;
}

.MuiCircularProgress-colorPrimary {
  color: white !important;
}
.MuiFormHelperText-contained {
  margin-left: 3px !important;
}
