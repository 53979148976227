.sidenav {
  position: fixed;
  top: 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  overflow: hidden;
  z-index: 99;
  box-shadow: $elevation-z8;
  width: $sidenav-width;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.373372px;
  background: #3a3a44f5;
  mix-blend-mode: normal;
  .sidenav__hold {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    z-index: 3;
    // opacity: 0;
    .scrollable {
      padding-bottom: 120px;
    }
    // Sidebar overlay
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
    }
    .brand-area {
      width: $sidenav-width;
      padding: 13px 18px;
      .brand {
        img {
          height: 24px;
          margin-right: 12px;
        }
        .brand__text {
          font-weight: bold;
          font-size: 1.125rem;
        }
      }
    }
    .sidebar-header {
      color: #66c2f5 !important;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
      letter-spacing: 1.14286px;
      margin: 26px 0 26px 22px;
      width: 215px;
      height: 38px;
    }
    .sidenav__user {
      width: $sidenav-width;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1.5rem 1rem 1rem;
      .username-photo {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      img {
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        margin-bottom: 8px;
      }
      .username {
        font-weight: 300;
        .material-icons {
          font-size: 13px;
        }
      }
      .user__menu {
        display: flex;
        justify-content: center;
        padding: 1rem 0;
      }
    }
  }
}

.sidenav-mobile {
  .sidenav {
    width: $sidenav-width;
  }
  .sidenav__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.54);
    z-index: -1;
  }
}

.navigation {
  button.nav-item {
    padding: 0px 20px 0px 20px;
  }
  .nav-item {
    display: flex;
    height: 48px;
    justify-content: space-between;
    button {
      padding: 0 20px;
    }
    &:hover {
      background: rgba($black, 0.2);
    }
    &.active {
      background: rgba($black, 0.2);
    }
    > button {
      justify-content: flex-start;
    }
    .item-icon {
      font-size: 18px;
    }
    .item-text {
      font-size: 0.875rem;
    }
    .icon-text {
      font-size: 0.875rem;
      display: inline-block;
      height: 21px;
      width: 21px;
      line-height: 21px;
    }
    .item-arrow {
      span {
        font-size: 18px;
      }
    }
  }
  .submenu {
    position: relative;
    background: rgba($black, 0.12);
    &:before,
    &:after {
      content: "";
      left: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      z-index: 3;
    }
    &:before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent);
      top: 0;
    }
    &:after {
      background: linear-gradient(-180deg, rgba(0, 0, 0, 0.06), transparent);
      bottom: 0;
    }
    .nav-item {
      height: 44px;
    }
  }
}
.badge {
  height: 20px;
  padding: 0 8px;
  margin: 0 4px 0 auto;
  border-radius: 12px;
  line-height: 20px;
  font-weight: 500;
}
.side-png 
{
  height: 16px;
  width: 16.59px;
  padding-left: 2px;
  
}
